import Swup from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupCustomPayloadPlugin from '@swup/custom-payload-plugin';
import SwupRouteNamePlugin from '@swup/route-name-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupMorphPlugin from 'swup-morph-plugin';
import Loadeer from "loadeer";
import Alpine from 'alpinejs';
import persist from '@alpinejs/persist'

const scrollPluginOptions = {
    doScrollingRightAway: false,
    animateScroll: window.matchMedia('(prefers-reduced-motion: reduce)').matches ? false : {
        betweenPages: true,
        samePageWithHash: true,
        samePage: true
    },
    offset: 1000,
}

const swup = new Swup({
    containers: [
        '#swup',
        '#main-nav',
        '#mobile-nav',
        '.pagename'
    ],
    plugins: [
        // new SwupScrollPlugin(scrollPluginOptions),
        new SwupMorphPlugin({
            containers: ['.drawer', '.articles', 'header'],
        }),
        new SwupRouteNamePlugin({
            routes: [
                { name: 'blog', path: '/blog' },
                { name: 'article', path: '/blog/:slug' },
            ]
        }),
        new SwupBodyClassPlugin(),
    ],
});

// swup.on('clickLink', e => {
//     console.log(e);
// })

swup.on('willReplaceContent', e => {
    // when navigating from overview to a field note or from field note to another field note
    if (window.innerWidth > 786 && swup.transition.from.includes('blog') && swup.transition.to.includes('blog/')) {
        return;
    }
    // when navigating away from a field note to overview
    if (window.innerWidth > 786 && swup.transition.from.includes('blog/') && swup.transition.to.includes('blog')) {
        return;
    }
    window.scrollTo(0,0);
})

swup.on('contentReplaced', e => {
    pageView();
})

swup.on('transitionEnd', () => {
    
})

window.swup = swup;
window.Alpine = Alpine;
Alpine.plugin(persist);
Alpine.start();

function pageView() {
    const root = document.querySelector('#swup');
    const loadeer = new Loadeer('[data-lazyload]', {root: root});
    loadeer.observe();
}

pageView();